<template>
  <div>
    <b-card>
      <h1>{{ bookingDetails.booking_id }}</h1>
      <b-row>
        <b-col cols="6">
          <b-card>
            <b-card-text>
              Type:<span style="cursor:pointer; font-weight:600"> {{ TripType(bookingDetails.moduleId) }}</span>
            </b-card-text>
            <b-card-text>
              Booking Date & Time:<span style="cursor:pointer; font-weight:600"> {{ bookingDetails.booking_date }}</span>
            </b-card-text>
            <b-card-text>
              Hotel Supplier ID:<span style="cursor:pointer; font-weight:600"> {{ bookingDetails.hotel_supplier_id }}</span>
            </b-card-text>
            <b-card-text>
              Flight Supplier ID:<span style="cursor:pointer; font-weight:600"> {{ bookingDetails.hotel_flight_supplier_id }}</span>
            </b-card-text>
            <b-card-text>
              Check-in & out:<span style="cursor:pointer; font-weight:600"> {{ bookingDetails.check_dates }}</span>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <form-wizard
      ref="refFormWizard"
      color="primary"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
    >

      <!-- Contact Details -->
      <tab-content
        title="Contact Details"
      >
        <new-contact-details
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :contact-details="contactDetails"
          @next-step="formWizardNextStep"
          @on-submit="getBookingDetailsById(ManualBookingId)"
        />

      </tab-content>

      <!-- Passengers Details -->
      <tab-content
        title="Passengers"
      >
        <new-passengers-details
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :room-details="roomDetails"
          :passenger-details="passengerDetails"
          @next-step="formWizardNextStep"
          @on-submit="getBookingDetailsById(ManualBookingId)"
        />
      </tab-content>

      <!-- Hotel Details -->
      <tab-content
        title="Hotel"
      >
        <new-hotel-details
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :currency="currency"
          :payment-type="paymentType"
          :hotel-details="getHotelDetail"
          @next-step="formWizardNextStep"
          @on-submit="getBookingDetailsById(ManualBookingId)"
        />
      </tab-content>

      <!-- Insurance Details -->
      <tab-content
        title="Insurance"
      >
        <new-insurance-details
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :insurance-details="insuranceDetails"
          @next-step="formWizardNextStep"
          @on-submit="getBookingDetailsById(ManualBookingId)"
        />
      </tab-content>

      <!-- Financial Details -->
      <tab-content
        title="Financial"
      >
        <new-financial-details
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :financial-details="getFinancialDetail"
          @next-step="formWizardNextStep"
          @on-submit="getBookingDetailsById(ManualBookingId)"
        />
      </tab-content>

      <!-- Payments Details -->
      <tab-content
        title="Payments"
      >
        <new-payment-details
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :payment-details="paymentDetails"
          @next-step="formWizardNextStep"
          @on-submit="getBookingDetailsById(ManualBookingId)"
        />
      </tab-content>

      <!-- Comments Details -->
      <tab-content
        title="Comments"
      >
        <NewCommentsDetails
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :comment-details="getCommentsDetail"
          @next-step="formWizardNextStep"
        />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import {
  FlightExcludeEnum, TripType, InstallmentType
} from '@/helpers/constant'
import bookingService from '@/services/booking/booking.service'
import { resConditionCheck } from '@/helpers'
import errorResponseHandler from '@/services/errorHandler'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from 'moment'
import currencyService from '@/services/currency/currency.service'
import NewContactDetails from './NewContactDetails.vue'
import NewPassengersDetails from './NewPassengersDetails.vue'
import NewHotelDetails from './NewHotelDetails.vue'
import NewInsuranceDetails from './NewInsuranceDetails.vue'
import NewFinancialDetails from './NewFinancialDetails.vue'
import NewPaymentDetails from './NewPaymentDetails.vue'
import NewCommentsDetails from './NewCommentsDetails.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    NewContactDetails,
    NewPassengersDetails,
    NewHotelDetails,
    NewInsuranceDetails,
    NewFinancialDetails,
    NewPaymentDetails,
    NewCommentsDetails,
    BRow,
    BCol,
    BCard,
    BCardText,
  },

  data() {
    return {
      ManualBookingId: this.$route.params.id,
      refFormWizard: '',
      FlightExcludeEnum,
      TripType,
      InstallmentType,
      bookingDetails: {},
      contactDetails: {},
      isLoaded: false,
      hotelDetails: {},
      insuranceDetails: [],
      financialDetails: {},
      paymentDetails: [],
      passengerDetails: [],
      roomDetails: [],
      commentDetails: {},
      userList: [],
      mainRes: [],
      paymentType: {},
      currency: {},
      currencyArr: []
    }
  },

  computed: {
    getHotelDetail() {
      if (this.hotelDetails !== '') {
        return this.hotelDetails
      }
      return {}
    },

    getFinancialDetail() {
      if (this.financialDetails !== '') {
        return this.financialDetails
      }
      return {}
    },

    getCommentsDetail() {
      if (this.commentDetails !== '') {
        return this.commentDetails
      }
      return {}
    }
  },

  async created() {
    this.getCurrencyName()
    await this.getUserList()
    await this.getBookingDetailsById(this.ManualBookingId)
  },

  // async mounted() {

  // },

  methods: {
    async getBookingDetailsById(id) {
      try {
        const res = await bookingService.getBookingDetailsById(id)
        if (resConditionCheck(res.status) && res.data) {
          this.isLoaded = true
          this.mainRes = res.data
          // Hotel Booking Details
          this.bookingDetails = res.data.header_details?.bookings[0];
          this.passengerDetails = (res.data?.passengers) ? res.data.passengers : []
          this.roomDetails = (res.data?.header_details?.bookings[0]?.searchCriteria?.rooms) ? res.data?.header_details?.bookings[0]?.searchCriteria?.rooms : []
          this.financialDetails = (res.data?.financials) ? res.data?.financials : {}
          this.paymentDetails = (res.data?.payments) ? res.data?.payments : []
          if (res.data?.insurance) {
            this.insuranceDetails = []
            res.data.insurance.forEach(item => {
              if (item.type !== 'Promocode') {
                this.insuranceDetails.push(item)
              }
            });
            this.financialDetails.insurances = this.insuranceDetails
          } else {
            this.insuranceDetails = []
          }
          this.paymentType = { id: res.data.header_details.paymentType, name: this.InstallmentType(res.data.header_details.paymentType) }
          let currencyObj = {}
          this.currencyArr.forEach(item => {
            if (item.id === this.bookingDetails.currencyId) {
              currencyObj = {
                id: item.id,
                name: item.code
              }
            }
          })
          this.currency = currencyObj
          this.userList.forEach(item => {
            if (item.user_id === this.bookingDetails.userId) {
              this.bookingDetails.username = `${item.first_name} ${item.last_name}`
              this.bookingDetails.email = item.email
            }
          })

          // Contact Details
          this.contactDetails = res.data.contact_details
          this.contactDetails.email = this.bookingDetails.email
          this.contactDetails.first_name = (this.contactDetails.first_name !== '') ? this.contactDetails.first_name : this.bookingDetails.username
          // eslint-disable-next-line no-multi-assign
          this.paymentDetails.quote_id = this.contactDetails.quote_id = this.bookingDetails.bookingQuoteId

          // Hotel Booking Details
          this.hotelDetails.hotel_id = (res.data.hotel?.id) ? res.data.hotel?.id : this.bookingDetails.id
          this.hotelDetails.bookingStatus = (res.data.hotel?.bookingStatus) ? res.data.hotel.bookingStatus : ''
          this.hotelDetails.supplierBookingId = (res.data.hotel?.supplierBookingId) ? res.data.hotel.supplierBookingId : ''
          this.hotelDetails.supplier = (res.data.hotel?.moduleInfo.supplier_name) ? res.data.hotel.moduleInfo.supplier_name : ''
          this.hotelDetails.hotel_name = (res.data.hotel?.moduleInfo.hotel_name) ? res.data.hotel.moduleInfo.hotel_name : ''
          this.hotelDetails.destination = (res.data.hotel?.searchCriteria.value) ? res.data.hotel.searchCriteria.value : ''
          this.hotelDetails.star_rating = (res.data.hotel?.moduleInfo.star_rating) ? res.data.hotel.moduleInfo.star_rating : ''
          this.hotelDetails.checkin_date = (res.data.hotel?.searchCriteria.checkin_date) ? res.data.hotel.searchCriteria.checkin_date : ''
          this.hotelDetails.checkout_date = (res.data.hotel?.searchCriteria.checkout_date) ? res.data.hotel.searchCriteria.checkout_date : ''
          this.hotelDetails.room_type = (res.data.hotel?.moduleInfo.room[0].room_type) ? res.data.hotel.moduleInfo.room[0].room_type : ''
          this.hotelDetails.board_type = (res.data.hotel?.moduleInfo.room[0].board_basis) ? res.data.hotel.moduleInfo.room[0].board_basis : ''
          this.hotelDetails.net_rate = (res.data.hotel?.netRate) ? res.data.hotel.netRate : ''
          this.hotelDetails.selling_price = (res.data.hotel?.sellingPrice) ? res.data.hotel.sellingPrice : ''
          this.hotelDetails.markup1 = (res.data.hotel?.moduleInfo.room[0].markup) ? res.data.hotel.moduleInfo.room[0].markup : ''
          this.hotelDetails.markup2 = (res.data.hotel?.moduleInfo.room[0].markup2) ? res.data.hotel.moduleInfo.room[0].markup2 : ''
          this.hotelDetails.installment_amount_1 = (res.data.hotel?.bookingInstallments[0]) ? res.data.hotel?.bookingInstallments[0].amount : ''
          this.hotelDetails.installment_amount_2 = (res.data.hotel?.bookingInstallments[1]) ? res.data.hotel?.bookingInstallments[1].amount : ''
          this.hotelDetails.address = (res.data.hotel?.moduleInfo?.address) ? res.data.hotel?.moduleInfo.address : ''
          this.hotelDetails.cancellation_term = (res.data.hotel?.moduleInfo?.room[0]?.note) ? res.data.hotel?.moduleInfo.room[0].note[0] : ''
          const dateArr = []
          dateArr.push(new Date(this.hotelDetails.checkin_date))
          dateArr.push(new Date(this.hotelDetails.checkout_date))
          this.hotelDetails.checkinout = dateArr
          const timeDiff = Math.abs(this.hotelDetails.checkinout[1].getTime() - this.hotelDetails.checkinout[0].getTime());
          this.hotelDetails.nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
          // this.hotelDetails = res.data.hotel

          this.bookingDetails.hotel_supplier_id = this.bookingDetails.supplierBookingId
          this.bookingDetails.booking_date = moment(this.bookingDetails.bookingDateTime).format('YYYY-MM-DD HH:MM:SS');
          this.bookingDetails.check_dates = `${moment(this.bookingDetails.searchCriteria.checkin_date).format('YYYY-MM-DD')} to ${moment(this.bookingDetails.searchCriteria.checkout_date).format('YYYY-MM-DD')}`
          this.bookingDetails.hotel_flight_supplier_id = (typeof this.bookingDetails?.flight_supplier_id !== 'undefined') ? this.bookingDetails?.flight_supplier_id : 'N/A';
          this.bookingDetails.quote_id = this.bookingDetails.bookingQuoteId
          this.bookingDetails.booking_id = this.bookingDetails.localBookingId

          // Comments Details
          this.commentDetails.remarks = (res.data.comments?.title) ? res.data.comments.title : ''
          this.commentDetails.notes = (res.data.comments?.notes) ? res.data.comments.notes : ''

          // eslint-disable-next-line no-return-assign
          this.paymentDetails.map(e => (e.paymentVia = this.bookingDetails.paymentVia))
        }
      } catch (error) {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async getUserList() {
      await bookingService.getAllUsers()
        .then(res => {
          if (resConditionCheck(res.status) && res.data) {
            this.userList = res.data.data;
          }
        }).catch(error => {
          errorResponseHandler(error)
        })
    },

    getCurrencyName() {
      currencyService.getCurrencyDropDown().then(res => {
        if (resConditionCheck(res.status) && res.data.data) {
          this.currencyArr = res.data.data
        }
      }).catch(error => {
        errorResponseHandler(error)
      })
    },
  },

  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    return {
      refFormWizard,
      formWizardNextStep,
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
