<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Name"
                label-for="h-name"
                label-cols-md="4"
              >
                <b-form-input
                  id="h-name"
                  v-model="contactDetails.first_name"
                  placeholder="Name"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Company Name"
                label-for="h-company-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <b-form-input
                    id="h-company-name"
                    v-model="contactDetails.company_name"
                    placeholder="Company Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Email"
                label-for="h-email"
                label-cols-md="4"
              >
                <b-form-input
                  id="h-email"
                  v-model="contactDetails.email"
                  type="email"
                  placeholder="Email"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Telephone"
                label-for="h-number"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone"
                  rules="required"
                >
                  <b-form-input
                    id="h-number"
                    v-model="contactDetails.telephone"
                    type="number"
                    placeholder="Telephone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Billing Name"
                label-for="h-billing"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Billing Name"
                  rules="required"
                >
                  <b-form-input
                    id="h-billing"
                    v-model="contactDetails.billing_name"
                    placeholder="Billing Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Billing Address line 1"
                label-for="h-address1"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Billing Address line 1"
                  rules="required"
                >
                  <b-form-input
                    id="h-address1"
                    v-model="contactDetails.address1"
                    type="text"
                    placeholder="Billing Address line 1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Billing Address line 2"
                label-for="h-address2"
                label-cols-md="4"
              >
                <b-form-input
                  id="h-address2"
                  v-model="contactDetails.address2"
                  type="text"
                  placeholder="Billing Address line 2"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="City"
                label-for="h-city"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <b-form-input
                    id="h-city"
                    v-model="contactDetails.city"
                    type="text"
                    placeholder="City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Country"
                label-for="h-country"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <v-select
                    id="h-country"
                    v-model="contactDetails.country"
                    :options="countries"
                    class="country"
                    placeholder="Select Country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Zipcode"
                label-for="h-zipcode"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ZipCode"
                  rules="required"
                >
                  <b-form-input
                    id="h-zipcode"
                    v-model="contactDetails.postal_code"
                    type="text"
                    placeholder="ZipCode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="VAT Number"
                label-for="h-vat"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="VAT Number"
                  rules="required"
                >
                  <b-form-input
                    id="h-vat"
                    v-model="contactDetails.vat_number"
                    type="text"
                    placeholder="VAT Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit and Next -->
            <b-col offset-md="5">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="isSubmitted"
                @click="saveContantDetails"
              >
                Save & Next
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import errorResponseHandler from '@/services/errorHandler'
import { required } from '@/@core/utils/validations/validations'
import {
  resConditionCheck
} from '@/helpers/constant'
import CountryService from '@/services/country/country.service'
import bookingService from '@/services/booking/booking.service'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  props: {
    contactDetails: {
      type: Object,
      required: true
    },
    // isLoaded: {
    //   type: Boolean,
    //   required: true
    // }
  },

  data() {
    return {
      countries: [],
      countriesList: [],
      isSubmitted: false,
      loading: false,
      required,
    }
  },

  async mounted() {
    this.getCountryList();
  },

  methods: {
    saveContantDetails() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isSubmitted = true
          this.loading = true;
          let countryObj = '';
          this.countriesList.forEach(item => {
            const code = item.iso2.trim();
            if (code === this.contactDetails.country_code) {
              countryObj = {
                id: item.id,
                code,
                name: item.country.replace(/[0-9]/g, '').replace(/[()]/g, '').replace(/[-]/g, ' ').trim()
              }
            }
          })

          const reqData = {
            user_name: this.contactDetails.first_name,
            email: this.contactDetails.email,
            telephone: this.contactDetails.telephone,
            city: this.contactDetails.city,
            country: countryObj,
            country_code: countryObj.id,
            zip_code: this.contactDetails.postal_code,
            billing_user_name: this.contactDetails.billing_name,
            address1: this.contactDetails.address1,
            address2: this.contactDetails.address2,
            company_name: this.contactDetails.company_name,
            vat_number: this.contactDetails.vat_number,
          }
          bookingService.saveContactDetails(this.contactDetails.quote_id, reqData).then(res => {
            if (resConditionCheck(res.status) && res.data) {
              this.loading = false
              this.isSubmitted = false
              this.$emit('on-submit')
              this.$emit('next-step')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Contact details is saved successfully.',
                  icon: "X-CircleIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            this.loading = false
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      })
    },

    getCountryList() {
      CountryService.getCountryList()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.countriesList = res.data.data;
            this.countries = res.data.data.map(e => ({
              label: e.country,
              value: e.code
            }))
          }
        }).catch(error => {
          errorResponseHandler(error)
        })
    },
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
