<template>
  <b-card-code>
    <b-table
      ref="refUserListTable"
      :items="items"
      class="position-relative"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(services)="data">
        {{ data.item.service }}
      </template>

      <template #cell(provider_cost)="data">
        {{ data.item.providerCost ? data.item.providerCost : '-' }}
      </template>

      <template #cell(markup)="data">
        {{ data.item.markup ? data.item.markup : '-' }}
      </template>

      <template #cell(markup2)="data">
        {{ data.item.markup2 ? data.item.markup2 : '-' }}
      </template>

      <template #cell(retail_price)="data">
        {{ data.item.retailPrice ? data.item.retailPrice : '-' }}
      </template>
    </b-table>
    <!-- submit and Next -->
    <b-col offset-md="5">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-1"
        @click="$emit('next-step')"
      >
        Save & Next
      </b-button>
    </b-col>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BCol, BButton, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BCardCode,
    BCol,
    BButton,
  },

  directives: {
    Ripple,
  },

  props: {
    financialDetails: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      items: [],
      secondObject: {},
      tableColumns: [
        { key: 'Services' },
        { key: 'provider_cost' },
        { key: 'markup_1' },
        { key: 'markup_2' },
        { key: 'retail_price' }
      ],
      loading: false
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      if (this.financialDetails?.bookings) {
        const hotelName = this.financialDetails?.bookings[0]?.moduleInfo?.hotel_name
        const { providerCost, sellingPrice } = this.financialDetails.bookings[0]
        const firstObject = {
          service: hotelName,
          providerCost,
          retailPrice: sellingPrice,
          markup_1: this.financialDetails?.bookings[0]?.moduleInfo?.room[0]?.markup,
          markup_2: this.financialDetails?.bookings[0]?.moduleInfo?.room[0]?.markup2
        }

        this.financialDetails.extras.forEach(e => {
          if (e.type === 'Promocode') {
            this.secondObject = {
              service: `DISCOUNT ${e.code}`,
              retailPrice: e.amount
            }
          }
        })

        const insuranceAmount = this.financialDetails.insurances.reduce((amount, e) => (
          amount + Number(e.amount)),
        0)
        const thirdObject = {
          service: 'Insurance',
          retailPrice: (insuranceAmount > 0) ? insuranceAmount : 0
        }

        if (Object.keys(this.secondObject).length) {
          this.items.push(firstObject, this.secondObject, thirdObject)
        } else {
          this.items.push(firstObject, thirdObject)
        }

        const totalAmount = this.items.reduce((amount, e) => (
          amount + Number(e.retailPrice)
        ), 0)
        const fourthObject = {
          service: 'Total',
          retailPrice: totalAmount
        }
        this.items.push(fourthObject)
      }
    }
  }

}
</script>
