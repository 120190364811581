<template>
  <b-card-code>
    <b-table
      ref="payModal"
      class="position-relative"
      responsive
      :items="paymentDetails"
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >

      <template #cell(No)="data">
        {{ data.index+1 }}
      </template>

      <template #cell(Type)="data">
        {{ PaymentType(data.item.paymentVia) }}
      </template>

      <template #cell(Deadline)="data">
        {{ data.item.installmentDate ? moment(data.item.installmentDate).format('YYYY-MM-DD') : '-' }}
      </template>

      <template #cell(Amount)="data">
        {{ data.item.amount ? data.item.amount: '-' }}
      </template>

      <template #cell(transaction_id)="data">
        {{ data.item.paymentTransactionId ? data.item.paymentTransactionId: '-' }}
      </template>

      <template #cell(status)="data">
        <b-badge pill :variant="`light-${resolveBookingStatusVariant(data.item.paymentStatus)}`">
          {{ BookingStatus(data.item.paymentStatus) }}
        </b-badge>
      </template>

      <template #cell(payment_date)="data">
        {{ data.item.paymentDate ? moment(data.item.paymentDate).format('YYYY-MM-DD') :'-' }}
      </template>

      <template #cell(action)="data">
        <b-button
          ref="button"
          v-b-modal.modal-add-details
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="light"
          class="p-0"
          @click="openPayModal(data.item.id)"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
            class="align-middle text-body"
          />
        </b-button>
      </template>

    </b-table>
    <!-- submit and Next -->
    <b-col offset-md="5">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mx-2"
        @click="$emit('next-step')"
      >
        Save & Next
        <!-- <div v-if="loading" class="spinner-border spinner-border-sm" /> -->
      </b-button>
    </b-col>
    <b-modal
      id="modal-add-details"
      title="Add Payment Detail"
      cancel-title="Close"
      ok-title="Save"
      size="md"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="updatePaymentDetails"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row col>
            <b-col md="12">
              <b-col md="12">
                <b-form-group
                  label="Transaction ID"
                  label-for="mc-transaction-id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Transaction ID"
                    rules="required"
                  >
                    <b-form-input
                      id="mc-transaction-id"
                      v-model="transactionId"
                      class="Transaction ID"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Transaction ID"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Deadline Date"
                  rules="required"
                >
                  <b-form-group
                    label="Select Deadline Date"
                    label-for="mc-select-deadline-date"
                    :state="errors.length > 0 ? false:null"
                  >
                    <date-picker
                      id="mc-select-deadline-date"
                      v-model="deadlineDate"
                      type="date"
                      format="MM/DD/YYYY"
                      :open-date="new Date()"
                      :disabled-dates="{to: new Date(Date.now() - 8640000)}"
                      placeholder="Select Deadline Date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Payment Date"
                  rules="required"
                >
                  <b-form-group
                    label="Select Payment Date"
                    label-for="mc-select-payment-date"
                    :state="errors.length > 0 ? false:null"
                  >
                    <date-picker
                      id="mc-select-payment-date"
                      v-model="paymentDate"
                      type="date"
                      format="MM/DD/YYYY"
                      :open-date="new Date()"
                      :disabled-dates="{to: new Date(Date.now() - 8640000)}"
                      placeholder="Select Payment Date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Payment Status"
                  rules="required"
                >
                  <b-form-group
                    label="Payment Status"
                    label-for="mc-select-payment-status"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      id="mc-select-payment-status"
                      v-model="paymentStatus"
                      :options="BookingStatusEnum"
                      class="Payment Status"
                      placeholder="Select Payment Status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-col>
          </b-row>
        </b-form>

      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, VBModal, BTable, BBadge
} from 'bootstrap-vue'
import {
  PaymentStatus,
  PaymentType,
  BookingStatusEnum,
  BookingStatus,
  resConditionCheck,
  resolveBookingStatusVariant
} from '@/helpers/constant'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import errorResponseHandler from '@/services/errorHandler'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BookingService from '@/services/booking/booking.service'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BTable,
    BBadge,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    DatePicker,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    paymentDetails: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {
      items: [],
      PaymentStatus,
      PaymentType,
      payId: '',
      transactionId: '',
      deadlineDate: '',
      paymentDate: '',
      paymentStatus: '',
      paymentsData: {},
      tableColumns: [
        { key: 'No' },
        { key: 'Type' },
        { key: 'Deadline' },
        { key: 'Amount' },
        { key: 'transaction_id' },
        { key: 'status' },
        { key: 'payment_date' },
        { key: 'Action' }
      ],
      loading: false,
      BookingStatus,
      BookingStatusEnum,
      resolveBookingStatusVariant,
    }
  },

  watch: {
    paymentDetails: {
      handler() {
      }
    }
  },

  methods: {
    updatePaymentDetails(bvModalEvt) {
      bvModalEvt.preventDefault()
      const installMentData = {
        installment_id: this.payId,
        insatllment_date: moment(this.deadlineDate).format('YYYY-MM-DD'),
        payment_date: moment(this.paymentDate).format('YYYY-MM-DD'),
        transaction_id: this.transactionId,
        status: this.paymentStatus
      }

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          BookingService.updateIntallment(this.paymentDetails.quote_id, installMentData).then(res => {
            if (resConditionCheck(res.status) && res.data) {
              this.$emit('on-submit')
              this.loading = false
              this.$nextTick(() => {
                this.$bvModal.hide('modal-add-details')
              })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "X-CircleIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            this.loading = false
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      })
    },

    openPayModal(id) {
      // eslint-disable-next-line array-callback-return
      this.paymentDetails.map(e => {
        if (e.id === id) {
          this.payId = e.id
          this.deadlineDate = (e.installmentDate) ? new Date(e.installmentDate) : ''
          this.paymentDate = (e.paymentDate) ? new Date(e.paymentDate) : ''
          this.transactionId = e.paymentTransactionId
          this.paymentStatus = e.paymentStatus
        }
      })
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
  .mx-datepicker{
    width: 100% !important;
  }
</style>
