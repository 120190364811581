var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"h-name","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"h-name","placeholder":"Name","disabled":true},model:{value:(_vm.contactDetails.first_name),callback:function ($$v) {_vm.$set(_vm.contactDetails, "first_name", $$v)},expression:"contactDetails.first_name"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Company Name","label-for":"h-company-name","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-company-name","placeholder":"Company Name"},model:{value:(_vm.contactDetails.company_name),callback:function ($$v) {_vm.$set(_vm.contactDetails, "company_name", $$v)},expression:"contactDetails.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"h-email","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"h-email","type":"email","placeholder":"Email","disabled":true},model:{value:(_vm.contactDetails.email),callback:function ($$v) {_vm.$set(_vm.contactDetails, "email", $$v)},expression:"contactDetails.email"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Telephone","label-for":"h-number","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Telephone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-number","type":"number","placeholder":"Telephone"},model:{value:(_vm.contactDetails.telephone),callback:function ($$v) {_vm.$set(_vm.contactDetails, "telephone", $$v)},expression:"contactDetails.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Billing Name","label-for":"h-billing","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Billing Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-billing","placeholder":"Billing Name"},model:{value:(_vm.contactDetails.billing_name),callback:function ($$v) {_vm.$set(_vm.contactDetails, "billing_name", $$v)},expression:"contactDetails.billing_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Billing Address line 1","label-for":"h-address1","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Billing Address line 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-address1","type":"text","placeholder":"Billing Address line 1"},model:{value:(_vm.contactDetails.address1),callback:function ($$v) {_vm.$set(_vm.contactDetails, "address1", $$v)},expression:"contactDetails.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Billing Address line 2","label-for":"h-address2","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"h-address2","type":"text","placeholder":"Billing Address line 2"},model:{value:(_vm.contactDetails.address2),callback:function ($$v) {_vm.$set(_vm.contactDetails, "address2", $$v)},expression:"contactDetails.address2"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"h-city","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-city","type":"text","placeholder":"City"},model:{value:(_vm.contactDetails.city),callback:function ($$v) {_vm.$set(_vm.contactDetails, "city", $$v)},expression:"contactDetails.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Country","label-for":"h-country","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"country",attrs:{"id":"h-country","options":_vm.countries,"placeholder":"Select Country","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (val) { return val.value; },"clearable":true},model:{value:(_vm.contactDetails.country),callback:function ($$v) {_vm.$set(_vm.contactDetails, "country", $$v)},expression:"contactDetails.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Zipcode","label-for":"h-zipcode","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"ZipCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-zipcode","type":"text","placeholder":"ZipCode"},model:{value:(_vm.contactDetails.postal_code),callback:function ($$v) {_vm.$set(_vm.contactDetails, "postal_code", $$v)},expression:"contactDetails.postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"VAT Number","label-for":"h-vat","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"VAT Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-vat","type":"text","placeholder":"VAT Number"},model:{value:(_vm.contactDetails.vat_number),callback:function ($$v) {_vm.$set(_vm.contactDetails, "vat_number", $$v)},expression:"contactDetails.vat_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"offset-md":"5"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.isSubmitted},on:{"click":_vm.saveContantDetails}},[_vm._v(" Save & Next "),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }