<template>
  <div>
    <confirm-delete ref="modal" :msg="deleteMsg" :title="titleMsg" />
    <b-card-code>
      <h3 class="mb-3">
        Rooms and People
      </h3>
      <div>
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >

          <!-- Row Loop -->
          <b-row ref="row">

            <!-- <h4 class="mt-2 mr-2 ml-2">
              Room {{ index + 1 }}
            </h4> -->
            <!-- Item Name -->
            <b-col md="2">
              <b-form-group
                label="Select Adult"
                label-for="mc-select-type"
              >
                <b-form-select
                  :options="adults"
                  class="type"
                  placeholder="Select Adult"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="true"
                  :disabled="items.length > 1"
                  @change="selectAdult($event, items.length + 1)"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group
                label="Select Child"
                label-for="mc-select-type"
              >
                <b-form-select
                  :options="child"
                  class="type"
                  placeholder="Select Child"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="true"
                  :disabled="items.length > 1"
                  @change="selectChild($event, items.length + 1)"
                />
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              lg="2"
              md="2"
              class="mt-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="items.length > 1"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add New</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>

        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >

          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
            class="border rounded p-2 mb-1"
          >
            <h2 class="p-0">
              Passenger Info
            </h2>
            <h4 class="mt-2 mr-2 ml-0 col-12">
              Room {{ index + 1 }}
            </h4>
            <!-- Item Name -->
            <b-col md="4" class="ml-0">
              <b-form-group
                label="Select Adult"
                label-for="mc-select-type"
              >
                <b-form-select
                  v-model="items[index].adult"
                  :options="adults"
                  class="type"
                  placeholder="Select Adult"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="true"
                  @change="selectAdult($event, index)"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Select Child"
                label-for="mc-select-type"
              >
                <b-form-select
                  v-model="items[index].child"
                  :options="child"
                  class="type"
                  placeholder="Select Child"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="true"
                  @change="selectChild($event, index)"
                />
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              lg="2"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-light"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                  size="26"
                  stroke="red"
                />
              </b-button>
            </b-col>

            <b-table
              ref="refUserListTable"
              :items="items[index].passengerRows"
              class="position-relative mt-3 mb-3 ml-1 borderd"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >

              <template #cell(adult_child)="data">
                {{ data.item.type }}
              </template>

              <template #cell(actions)="data">
                <b-button
                  ref="button"
                  v-b-modal.modal-update-traveler
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="light"
                  class="p-0 mr-1"
                  @click="getTravelerDetail(data.item.travler_id, data.item.passenger_code, items[index].id)"
                >
                  <feather-icon
                    v-show="data.item.travler_id !== '-'"
                    icon="EditIcon"
                    size="16"
                    stroke="green"
                    class="align-middle text-body"
                  />
                  <feather-icon
                    v-show="data.item.travler_id === '-'"
                    icon="PlusSquareIcon"
                    size="16"
                    stroke="green"
                    class="align-middle text-body"
                  />
                </b-button>
                <b-button
                  ref="button"
                  variant="light"
                  class="p-0"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    class="align-middle text-body"
                    stroke="red"
                    @click="deleteModal()"
                  />
                </b-button>
              </template>

            </b-table>

          </b-row>

        </b-form>
      </div>

      <b-col offset-md="5">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="SavePassengers"
        >
          Save & Next
          <div v-if="loading" class="spinner-border spinner-border-sm" />
        </b-button>
      </b-col>
    </b-card-code>

    <b-modal
      id="modal-update-traveler"
      title="Update Traveler Detail"
      cancel-title="Close"
      ok-title="Save"
      size="md"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="AddUpdateTraveler"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row col>
            <b-col md="12">
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-group
                    label="Title"
                    label-for="mc-select-title"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      v-model="travelerReqForm.prefix"
                      :options="TravelerTitles"
                      class="title"
                      placeholder="Select Title"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="First Name"
                  label-for="mc-First-Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      id="mc-First-Name"
                      v-model="travelerReqForm.first_name"
                      class="First Name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="First Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Last Name"
                  label-for="mc-last-Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      id="mc-last-Name"
                      v-model="travelerReqForm.last_name"
                      class="Last Name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Last Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Date of Birth"
                  label-for="mc-dob-date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Date of Birth"
                    rules="required"
                  >
                    <date-picker
                      id="mc-dob-date"
                      v-model="travelerReqForm.birth_date"
                      type="date"
                      format="MM/DD/YYYY"
                      :open-date="new Date()"
                      :disabled-dates="{to: new Date(Date.now() - 8640000)}"
                      placeholder="Select Date of Birth"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Document Number"
                  label-for="mc-doc-num"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Document Number"
                    rules="required"
                  >
                    <b-form-input
                      id="mc-doc-num"
                      v-model="travelerReqForm.document_number"
                      class="Document Number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Document Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Expiration"
                  rules="required"
                >
                  <b-form-group
                    label="Expiration"
                    label-for="mc-exp-date"
                    :state="errors.length > 0 ? false:null"
                  >
                    <date-picker
                      id="mc-exp-date"
                      v-model="travelerReqForm.document_expiry"
                      type="date"
                      format="MM/DD/YYYY"
                      :open-date="new Date()"
                      placeholder="Select Expiration"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-col>
          </b-row>
        </b-form>

      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BForm, BFormGroup, BRow, BCol, BButton, BTable, BFormSelect, BFormInput, VBModal,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import {
  adults,
  child,
  deleteConfirmMsg,
  TravelerTitles,
  TravelerTitleType
} from '@/helpers/constant'
import vSelect from 'vue-select'
import moment from 'moment'
import { resConditionCheck } from '@/helpers'
import errorResponseHandler from '@/services/errorHandler'
import BookingService from '@/services/booking/booking.service'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ConfirmDelete from "../../../confirm-delete/ConfirmDelete.vue"

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BCardCode,
    BTable,
    BFormSelect,
    ConfirmDelete,
    ValidationObserver,
    ValidationProvider,
    DatePicker,
    vSelect,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],

  props: {
    roomDetails: {
      type: Array,
      required: true
    },
    passengerDetails: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      quoteId: this.$route.params.id ? this.$route.params.id : '',
      items: [
        {
          id: 1,
          adult: 1,
          child: 0,
          prevHeight: 0,
        },
      ],
      travelerReqForm: {
        travler_id: '',
        room_id: '',
        birth_date: '',
        passenger_code: '',
        gender: '',
        is_lead: true,
        is_agent: true,
        prefix: '',
        first_name: '',
        last_name: '',
        document_number: '',
        document_expiry: ''
      },
      nextTodoId: 2,
      adults,
      child,
      tableColumns: [
        { key: 'adult_child', label: 'ADULT/CHILD' },
        { key: 'prefix', label: 'Title' },
        { key: 'first_name' },
        { key: 'last_name' },
        { key: 'birth_date', label: 'DOB' },
        { key: 'document_number' },
        { key: 'document_expiry', label: 'EXPIRATION' },
        { key: 'actions' }
      ],
      loading: false,
      titleMsg: '',
      deleteMsg: '',
      TravelerTitles,
      TravelerTitleType,
    }
  },

  mounted() {
    if (this.roomDetails.length > 0) {
      this.items = this.roomDetails.map((e, idx) => {
        const adultData = []
        const childs = []
        if (this.passengerDetails[idx].length > 0) {
          this.passengerDetails[idx].forEach(tr => {
            if (tr.traveller.passanger_code === 'ADT') {
              adultData.push(tr)
            } else {
              childs.push(tr)
            }
          });
        }
        return {
          id: idx + 1,
          adult: e.adults,
          child: e.children.length,
          prevHeight: 0,
          adultsData: adultData,
          childsData: childs
        }
      })
    }
    this.addPassengerData()
  },

  methods: {

    addPassengerData() {
      this.items = this.items.map(e => ({
        ...e,
        count: Number(e.adult) + Number(e.child),
      }))
      this.items = this.items.map(e => ({
        ...e,
        adultRows: Array.from(new Array(e.adult), (x, i) => ({
          label: i,
          value: i,
          type: 'Adult',
          travler_id: (e?.adultsData[i]?.id) ? e?.adultsData[i]?.id : '-',
          room_id: (e?.adultsData[i]?.roomId) ? e?.adultsData[i]?.roomId : e.id - 1,
          birth_date: (e?.adultsData[i]?.traveller.birthdate) ? moment(e?.adultsData[i]?.traveller.birthdate).format('YYYY-MM-DD') : '-',
          passenger_code: (e?.adultsData[i]?.traveller.passanger_code) ? e?.adultsData[i]?.traveller.passanger_code : 'ADT',
          gender: 'Male',
          is_lead: true,
          is_agent: true,
          prefix: (e?.adultsData[i]?.traveller.prefix) ? e?.adultsData[i]?.traveller.prefix : '-',
          first_name: (e?.adultsData[i]?.traveller.first_name) ? e?.adultsData[i]?.traveller.first_name : '-',
          last_name: (e?.adultsData[i]?.traveller.last_name) ? e?.adultsData[i]?.traveller.last_name : '-',
          document_number: (e?.adultsData[i]?.traveller.document_number) ? e?.adultsData[i]?.traveller.document_number : '-',
          document_expiry: (e?.adultsData[i]?.traveller.document_expiry) ? moment(e?.adultsData[i]?.traveller.document_expiry).format('YYYY-MM-DD') : '-'

        })),
        childRows: Array.from(new Array(e.child), (x, i) => ({
          label: i,
          value: i,
          type: 'Child',
          travler_id: (e?.childsData[i]?.id) ? e?.childsData[i]?.id : '-',
          room_id: (e?.childsData[i]?.roomId) ? e?.childsData[i]?.roomId : e.id - 1,
          birth_date: (e?.childsData[i]?.traveller.birthdate) ? moment(e?.childsData[i]?.traveller.birthdate).format('YYYY-MM-DD') : '-',
          passenger_code: (e?.childsData[i]?.traveller.passanger_code) ? e?.childsData[i]?.traveller.passanger_code : 'CHD',
          gender: '-',
          is_lead: true,
          is_agent: true,
          prefix: (e?.childsData[i]?.traveller.prefix) ? e?.childsData[i]?.traveller.prefix : '-',
          first_name: (e?.childsData[i]?.traveller.first_name) ? e?.childsData[i]?.traveller.first_name : '-',
          last_name: (e?.childsData[i]?.traveller.last_name) ? e?.childsData[i]?.traveller.last_name : '-',
          document_number: (e?.childsData[i]?.traveller.document_number) ? e?.childsData[i]?.traveller.document_number : '-',
          document_expiry: (e?.childsData[i]?.traveller.document_expiry) ? moment(e?.childsData[i]?.traveller.document_expiry).format('YYYY-MM-DD') : '-'

        })),
      }))

      this.items = this.items.map(e => ({
        ...e,
        passengerRows: [...e.adultRows, ...e.childRows]
      }))
    },

    AddUpdateRooms() {},

    AddUpdateTraveler(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          if (this.travelerReqForm.prefix === 'Mr') {
            this.travelerReqForm.gender = 'Male'
          } else if (this.travelerReqForm.prefix === 'Mrs' || this.travelerReqForm.prefix === 'Ms') {
            this.travelerReqForm.gender = 'Female'
          }
          this.travelerReqForm.birth_date = moment(this.travelerReqForm.birth_date).format('YYYY-MM-DD');
          this.travelerReqForm.document_expiry = moment(this.travelerReqForm.document_expiry).format('YYYY-MM-DD');
          BookingService.addUpdateTraveler(this.quoteId, this.travelerReqForm).then(res => {
            if (resConditionCheck(res.status) && res.data) {
              this.loading = false
              this.$nextTick(() => {
                this.$emit('on-submit')
                this.$bvModal.hide('modal-update-traveler')
              })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "X-CircleIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            this.loading = false
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      })
    },

    getTravelerDetail(travlerId, code, roomId) {
      // if (travlerId !== '-') {
      this.items[roomId - 1].passengerRows.forEach(item => {
        if (item.travler_id === travlerId) {
          this.travelerReqForm.travler_id = (travlerId !== '-') ? travlerId : ''
          this.travelerReqForm.room_id = (item.room_id !== '') ? item.room_id : roomId
          this.travelerReqForm.birth_date = (item.birth_date !== '-') ? new Date(item.birth_date) : ''
          this.travelerReqForm.passenger_code = (item.passenger_code !== '-') ? item.passenger_code : code
          this.travelerReqForm.is_lead = true
          this.travelerReqForm.is_agent = true
          this.travelerReqForm.prefix = (item.prefix !== '-') ? item.prefix : ''
          this.travelerReqForm.first_name = (item.first_name !== '-') ? item.first_name : ''
          this.travelerReqForm.last_name = (item.last_name !== '-') ? item.last_name : ''
          this.travelerReqForm.document_number = (item.document_number !== '-') ? item.document_number : ''
          this.travelerReqForm.document_expiry = (item.document_expiry !== '-') ? new Date(item.document_expiry) : ''
        } else {
          this.travelerReqForm.travler_id = ''
          this.travelerReqForm.room_id = roomId
          this.travelerReqForm.birth_date = ''
          this.travelerReqForm.passenger_code = code
          this.travelerReqForm.is_lead = true
          this.travelerReqForm.is_agent = true
          this.travelerReqForm.prefix = ''
          this.travelerReqForm.first_name = ''
          this.travelerReqForm.last_name = ''
          this.travelerReqForm.document_number = ''
          this.travelerReqForm.document_expiry = ''
        }
      })
      // }
    },

    SavePassengers() {
      this.$emit('next-step')
    },

    selectAdult(e, index) {
      this.items[index].count = this.items[index].adult + this.items[index].child
      if (this.items[index].count <= 6) {
        this.items[index].adult = e
        this.addPassengerData()
      } else {
        // console.log('Maximum guests per room cannot be more than 6')
      }
    },

    selectChild(e, index) {
      this.items[index].count = this.items[index].adult + this.items[index].child
      if (this.items[index].count <= 6) {
        this.items[index].child = e
        this.addPassengerData()
      } else {
        // console.log('Maximum guests per room cannot be more than 6')
      }
    },

    repeateAgain() {
      if (this.items.length < 2) {
        this.items.push({
          id: this.nextTodoId += this.nextTodoId,
          adult: 1,
          child: 0
        })
        this.addPassengerData()

        // this.$nextTick(() => {
        //   this.trAddHeight(this.$refs.row[0].offsetHeight)
        // })
      }
    },
    removeItem(index) {
      this.titleMsg = deleteConfirmMsg
      this.deleteMsg = `Are you sure you want to delete this Room?`
      this.$refs.modal.show()
      if (this.items.length > 1) {
        this.items.splice(index, 1)
        // this.trTrimHeight(this.$refs.row[0].offsetHeight)
      }
    },
    // initTrHeight() {
    //   this.trSetHeight(null)
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight)
    //   })
    // },

    deleteModal() {
      this.titleMsg = deleteConfirmMsg
      this.deleteMsg = `Are you sure you want to delete this Adult/Child?`
      this.$refs.modal.show()
    },
  },
}
</script>

 <style lang="scss" scoped>
// .repeater-form {
//   overflow: hidden;
//   transition: .35s height;
// }
// </style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
