var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{attrs:{"col":""}},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select Status","label-for":"mc-select-status","state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"type",attrs:{"id":"mc-select-status","options":_vm.BookingStatusEnum,"placeholder":"Select Booking Status","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.hotelDetails.bookingStatus),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "bookingStatus", $$v)},expression:"hotelDetails.bookingStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Supplier ID","label-for":"mc-supplier"}},[_c('validation-provider',{attrs:{"name":"Supplier ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"supplier_id",attrs:{"id":"mc-supplier","state":errors.length > 0 ? false : null,"placeholder":"Supplier ID"},model:{value:(_vm.hotelDetails.supplierBookingId),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "supplierBookingId", $$v)},expression:"hotelDetails.supplierBookingId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Supplier","label-for":"mc-supplier-name"}},[_c('validation-provider',{attrs:{"name":"Supplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"supplier",attrs:{"id":"mc-supplier-name","state":errors.length > 0 ? false : null,"placeholder":"Supplier"},model:{value:(_vm.hotelDetails.supplier),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "supplier", $$v)},expression:"hotelDetails.supplier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Hotel Name","label-for":"mc-hotel-name"}},[_c('validation-provider',{attrs:{"name":"Hotel name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hotel",attrs:{"id":"mc-hotel-name","state":errors.length > 0 ? false : null,"placeholder":"Hotel Name"},model:{value:(_vm.hotelDetails.hotel_name),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "hotel_name", $$v)},expression:"hotelDetails.hotel_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Destination","label-for":"mc-destination"}},[_c('validation-provider',{attrs:{"name":"Destination","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mc-destination",attrs:{"options":_vm.DestinationList,"placeholder":"Select Destination","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.hotelDetails.destination),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "destination", $$v)},expression:"hotelDetails.destination"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Star Rating","label-for":"mc-star"}},[_c('validation-provider',{attrs:{"name":"Star Rating","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mc-star",attrs:{"options":_vm.StarRatingEnum,"placeholder":"Select Star Rating","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.hotelDetails.star_rating),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "star_rating", $$v)},expression:"hotelDetails.star_rating"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Checkin date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select Checkin-Checkout Date","label-for":"mc-select-date","state":errors.length > 0 ? false : null}},[_c('date-picker',{attrs:{"id":"mc-select-date","type":"date","range":"","format":"MM/DD/YYYY","open-date":new Date(),"disabled-date":_vm.disabledRange,"placeholder":"Select date range"},model:{value:(_vm.bookingDates),callback:function ($$v) {_vm.bookingDates=$$v},expression:"bookingDates"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nights","label-for":"mc-night"}},[_c('b-form-input',{staticClass:"nights",attrs:{"id":"mc-night","placeholder":"Nights","disabled":true},model:{value:(_vm.nights),callback:function ($$v) {_vm.nights=$$v},expression:"nights"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Room Type","label-for":"mc-room-type"}},[_c('validation-provider',{attrs:{"name":"Room Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"room-type",attrs:{"id":"mc-room-type","state":errors.length > 0 ? false : null,"placeholder":"Room type"},model:{value:(_vm.hotelDetails.room_type),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "room_type", $$v)},expression:"hotelDetails.room_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Board Type","label-for":"mc-board-type"}},[_c('validation-provider',{attrs:{"name":"Board Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"board-type",attrs:{"id":"mc-board-type","state":errors.length > 0 ? false : null,"placeholder":"Board type"},model:{value:(_vm.hotelDetails.board_type),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "board_type", $$v)},expression:"hotelDetails.board_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Retails Price","label-for":"mc-retail"}},[_c('validation-provider',{attrs:{"name":"Retail Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"retail-price",attrs:{"id":"mc-retail","state":errors.length > 0 ? false : null,"placeholder":"Retail Price"},model:{value:(_vm.hotelDetails.selling_price),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "selling_price", $$v)},expression:"hotelDetails.selling_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Provider Cost","label-for":"mc-provider"}},[_c('validation-provider',{attrs:{"name":"Provider Cost","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"nights",attrs:{"id":"mc-provider","state":errors.length > 0 ? false : null,"placeholder":"Provider Cost"},model:{value:(_vm.hotelDetails.net_rate),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "net_rate", $$v)},expression:"hotelDetails.net_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Markup 1","label-for":"markup1"}},[_c('validation-provider',{attrs:{"name":"Markup 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"markup1",attrs:{"id":"markup1","state":errors.length > 0 ? false : null,"placeholder":"Markup 1"},model:{value:(_vm.hotelDetails.markup1),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "markup1", $$v)},expression:"hotelDetails.markup1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Markup 2","label-for":"markup2"}},[_c('validation-provider',{attrs:{"name":"Markup 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"markup2",attrs:{"id":"markup2","state":errors.length > 0 ? false : null,"placeholder":"Markup 2"},model:{value:(_vm.hotelDetails.markup2),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "markup2", $$v)},expression:"hotelDetails.markup2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Installment","label-for":"first-installment"}},[_c('validation-provider',{attrs:{"name":"First Installment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"first-installment",attrs:{"id":"first-installment","state":errors.length > 0 ? false : null,"placeholder":"First Installment"},model:{value:(_vm.hotelDetails.installment_amount_1),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "installment_amount_1", $$v)},expression:"hotelDetails.installment_amount_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.paymentType.id === 2)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Second Installment","label-for":"second-installment"}},[_c('validation-provider',{attrs:{"name":"Second Installment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"second-installment",attrs:{"id":"second-installment","state":errors.length > 0 ? false : null,"placeholder":"Second Installment"},model:{value:(_vm.hotelDetails.installment_amount_2),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "installment_amount_2", $$v)},expression:"hotelDetails.installment_amount_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,531540720)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Address","label-for":"mc-address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_c('quill-editor',{attrs:{"id":"mc-address","options":_vm.snowOption,"preserve-whitespace":false},model:{value:(_vm.hotelDetails.address),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "address", $$v)},expression:"hotelDetails.address"}})],_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Important Information","label-for":"important-info"}},[_c('div',{staticClass:"bg-light py-1 border",domProps:{"innerHTML":_vm._s(_vm.hotelDetails.important_info)}})])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Cancellation Terms","label-for":"cancellation"}},[_c('validation-provider',{attrs:{"name":"Cancellation Terms","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [[_c('quill-editor',{attrs:{"id":"cancellation","options":_vm.snowOption,"preserve-whitespace":false},model:{value:(_vm.hotelDetails.cancellation_term),callback:function ($$v) {_vm.$set(_vm.hotelDetails, "cancellation_term", $$v)},expression:"hotelDetails.cancellation_term"}})],_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"col":""}},[_c('b-col',{staticClass:"mt-2",attrs:{"md":"7"}},[_c('div',{staticClass:"helper"}),_c('div',{staticClass:"drop align-center",on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.onDrop}},[_c('div',{staticClass:"helper"}),(!_vm.imagePreview && !_vm.uploadImage)?_c('label',{staticClass:"btn btn-primary"},[_vm._v(" SELECT OR DROP AN IMAGE "),_c('input',{attrs:{"type":"file","accept":"image/*","name":"image"},on:{"change":_vm.onChange}})]):_vm._e(),(_vm.imagePreview)?_c('div',{staticClass:" display-inline align-center",class:{ image: true }},[_c('img',{staticClass:"img mt-2",attrs:{"src":_vm.imagePreview,"alt":"Image"}}),_c('br'),_c('br'),_c('button',{staticClass:"btn btn-danger mb-2",on:{"click":_vm.removeFile}},[_vm._v(" REMOVE ")])]):_vm._e()]),_c('b-alert',{staticClass:"mt-2",attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Allowed JPG, GIF or PNG. Max size of 1MB.")])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"offset-md":"5"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.isSubmitted},on:{"click":_vm.saveHotelDetails}},[_vm._v(" Save & Next "),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }