<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Status"
              rules="required"
            >
              <b-form-group
                label="Select Status"
                label-for="mc-select-status"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="mc-select-status"
                  v-model="hotelDetails.bookingStatus"
                  :options="BookingStatusEnum"
                  class="type"
                  placeholder="Select Booking Status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <b-form-group label="Supplier ID" label-for="mc-supplier">
              <validation-provider
                #default="{ errors }"
                name="Supplier ID"
                rules="required"
              >
                <b-form-input
                  id="mc-supplier"
                  v-model="hotelDetails.supplierBookingId"
                  class="supplier_id"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Supplier ID"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Supplier" label-for="mc-supplier-name">
              <validation-provider
                #default="{ errors }"
                name="Supplier"
                rules="required"
              >
                <b-form-input
                  id="mc-supplier-name"
                  v-model="hotelDetails.supplier"
                  class="supplier"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Supplier"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Hotel Name" label-for="mc-hotel-name">
              <validation-provider
                #default="{ errors }"
                name="Hotel name"
                rules="required"
              >
                <b-form-input
                  id="mc-hotel-name"
                  v-model="hotelDetails.hotel_name"
                  class="hotel"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Hotel Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Destination" label-for="mc-destination">
              <validation-provider
                #default="{ errors }"
                name="Destination"
                rules="required"
              >
                <v-select
                  v-model="hotelDetails.destination"
                  :options="DestinationList"
                  class="mc-destination"
                  placeholder="Select Destination"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Star Rating" label-for="mc-star">
              <validation-provider
                #default="{ errors }"
                name="Star Rating"
                rules="required"
              >
                <v-select
                  v-model="hotelDetails.star_rating"
                  :options="StarRatingEnum"
                  class="mc-star"
                  placeholder="Select Star Rating"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Checkin date"
              rules="required"
            >
              <b-form-group
                label="Select Checkin-Checkout Date"
                label-for="mc-select-date"
                :state="errors.length > 0 ? false : null"
              >
                <date-picker
                  id="mc-select-date"
                  v-model="bookingDates"
                  type="date"
                  range
                  format="MM/DD/YYYY"
                  :open-date="new Date()"
                  :disabled-date="disabledRange"
                  placeholder="Select date range"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <b-form-group label="Nights" label-for="mc-night">
              <b-form-input
                id="mc-night"
                v-model="nights"
                class="nights"
                placeholder="Nights"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Room Type" label-for="mc-room-type">
              <validation-provider
                #default="{ errors }"
                name="Room Type"
                rules="required"
              >
                <b-form-input
                  id="mc-room-type"
                  v-model="hotelDetails.room_type"
                  class="room-type"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Room type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Board Type" label-for="mc-board-type">
              <validation-provider
                #default="{ errors }"
                name="Board Type"
                rules="required"
              >
                <b-form-input
                  id="mc-board-type"
                  v-model="hotelDetails.board_type"
                  class="board-type"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Board type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Retails Price" label-for="mc-retail">
              <validation-provider
                #default="{ errors }"
                name="Retail Price"
                rules="required"
              >
                <b-form-input
                  id="mc-retail"
                  v-model="hotelDetails.selling_price"
                  class="retail-price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Retail Price"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Provider Cost" label-for="mc-provider">
              <validation-provider
                #default="{ errors }"
                name="Provider Cost"
                rules="required"
              >
                <b-form-input
                  id="mc-provider"
                  v-model="hotelDetails.net_rate"
                  class="nights"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Provider Cost"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Markup 1" label-for="markup1">
              <validation-provider
                #default="{ errors }"
                name="Markup 1"
                rules="required"
              >
                <b-form-input
                  id="markup1"
                  v-model="hotelDetails.markup1"
                  class="markup1"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Markup 1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Markup 2" label-for="markup2">
              <validation-provider
                #default="{ errors }"
                name="Markup 2"
                rules="required"
              >
                <b-form-input
                  id="markup2"
                  v-model="hotelDetails.markup2"
                  class="markup2"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Markup 2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="First Installment"
              label-for="first-installment"
            >
              <validation-provider
                #default="{ errors }"
                name="First Installment"
                rules="required"
              >
                <b-form-input
                  id="first-installment"
                  v-model="hotelDetails.installment_amount_1"
                  class="first-installment"
                  :state="errors.length > 0 ? false : null"
                  placeholder="First Installment"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="paymentType.id === 2" md="6">
            <b-form-group
              label="Second Installment"
              label-for="second-installment"
            >
              <validation-provider
                #default="{ errors }"
                name="Second Installment"
                rules="required"
              >
                <b-form-input
                  id="second-installment"
                  v-model="hotelDetails.installment_amount_2"
                  class="second-installment"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Second Installment"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Address" label-for="mc-address">
              <validation-provider
                #default="{ errors }"
                name="Address"
                rules="required"
              >
                <template>
                  <quill-editor
                    id="mc-address"
                    v-model="hotelDetails.address"
                    :options="snowOption"
                    :preserve-whitespace="false"
                  />
                </template>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Important Information"
              label-for="important-info"
            >
              <div
                class="bg-light py-1 border"
                v-html="hotelDetails.important_info"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Cancellation Terms" label-for="cancellation">
              <validation-provider
                #default="{ errors }"
                name="Cancellation Terms"
                rules="required"
              >
                <template>
                  <quill-editor
                    id="cancellation"
                    v-model="hotelDetails.cancellation_term"
                    :options="snowOption"
                    :preserve-whitespace="false"
                  />
                </template>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row col>
          <b-col md="7" class="mt-2">
            <div class="helper" />
            <div class="drop align-center" @dragover.prevent @drop="onDrop">
              <div class="helper" />
              <label
                v-if="!imagePreview && !uploadImage"
                class="btn btn-primary"
              >
                SELECT OR DROP AN IMAGE
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  @change="onChange"
                >
              </label>
              <div
                v-if="imagePreview"
                class=" display-inline align-center"
                :class="{ image: true }"
              >
                <img :src="imagePreview" alt="Image" class="img mt-2">
                <br>
                <br>
                <button class="btn btn-danger mb-2" @click="removeFile">
                  REMOVE
                </button>
              </div>
            </div>
            <b-alert class="mt-2" variant="primary" show>
              <div class="alert-body">
                <span>Allowed JPG, GIF or PNG. Max size of 1MB.</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <!-- submit and reset -->
        <b-row>
          <b-col offset-md="5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="isSubmitted"
              @click="saveHotelDetails"
            >
              Save & Next
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BAlert,
  BButton
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  resConditionCheck,
  BookingStatusEnum,
  StarRatingEnum
} from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BookingService from "@/services/booking/booking.service";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BCardCode,
    DatePicker,
    quillEditor,
    BAlert,
    BButton
  },

  directives: {
    Ripple
  },

  props: {
    hotelDetails: {
      type: Object,
      required: true
    },
    currency: {
      type: Object,
      required: true
    },
    paymentType: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      DestinationList: [],
      nights: this.hotelDetails.nights || 0,
      uploadImage: "",
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      },

      imagePreview: "",
      image: "",
      bookingDates: this.hotelDetails.checkinout || [],
      loading: false,
      isSubmitted: false,
      BookingStatusEnum,
      StarRatingEnum
    };
  },

  watch: {
    bookingDates: {
      handler(newValue) {
        if (newValue[0] !== null) {
          this.hotelDetails.checkin_date = newValue[0]
            ? moment(newValue[0]).format("YYYY-MM-DD")
            : "";
          this.hotelDetails.checkout_date = newValue[1]
            ? moment(newValue[1]).format("YYYY-MM-DD")
            : "";
          const timeDiff = Math.abs(
            newValue[1].getTime() - newValue[0].getTime()
          );
          this.nights = Math.ceil(timeDiff / (1000 * 3600 * 24));
        } else {
          this.hotelDetails.checkin_date = "";
          this.hotelDetails.checkout_date = "";
          this.nights = 0;
        }
      }
    }
  },

  async mounted() {
    this.getHotelDestination();
    this.getImportantInfo(this.hotelDetails.moduleId, 1); // call to get important info
  },

  methods: {
    saveHotelDetails() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isSubmitted = true;
          this.loading = true;
          const formdata = new FormData();
          formdata.append("status", this.hotelDetails.bookingStatus);
          formdata.append(
            "hotel_supplier_id",
            this.hotelDetails.supplierBookingId
          );
          formdata.append("supplier", this.hotelDetails.supplier);
          formdata.append("hotel_name", this.hotelDetails.hotel_name);
          formdata.append("destination", this.hotelDetails.destination);
          formdata.append("star_rating", this.hotelDetails.star_rating);
          formdata.append("checkin_date", this.hotelDetails.checkin_date);
          formdata.append("checkout_date", this.hotelDetails.checkout_date);
          formdata.append("currency", this.currency.id);
          formdata.append("room_type", this.hotelDetails.room_type);
          formdata.append("baord_type", this.hotelDetails.board_type);
          formdata.append("net_rate", this.hotelDetails.net_rate);
          formdata.append("selling_price", this.hotelDetails.selling_price);
          formdata.append("markup1", this.hotelDetails.markup1);
          formdata.append("markup2", this.hotelDetails.markup2);
          formdata.append("payment_type", this.paymentType.name.toLowerCase()); // need manage when steps completed
          formdata.append(
            "installment_amount_1",
            this.hotelDetails.installment_amount_1
          );
          if (this.paymentType.id === 2) {
            formdata.append(
              "installment_amount_2",
              this.hotelDetails.installment_amount_2
            );
          }
          formdata.append("address", this.hotelDetails.address);
          formdata.append(
            "cancellation_term",
            this.hotelDetails.cancellation_term
          );

          BookingService.saveHotelDetails(this.hotelDetails.hotel_id, formdata)
            .then(res => {
              if (resConditionCheck(res.status) && res.data) {
                this.isSubmitted = false;
                this.loading = false;
                this.$emit("on-submit");
                this.$emit("next-step");
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "X-CircleIcon",
                    variant: "success"
                  }
                });
              }
            })
            .catch(error => {
              this.loading = false;
              const errorData = errorResponseHandler(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger"
                }
              });
            });
        }
      });
    },

    async getHotelDestination() {
      await BookingService.getHotelDestinationsList()
        .then(res => {
          if (resConditionCheck(res.status) && res.data) {
            this.DestinationList = res.data.data.map(e => ({
              label: `${e.name}, ${e.country}`,
              value: e.name
            }));
          }
        })
        .catch(error => {
          errorResponseHandler(error);
        });
    },

    async getImportantInfo(moduleId = 1, lang = 1) {
      await BookingService.getImportantInfo(moduleId, lang)
        .then(res => {
          if (resConditionCheck(res.status) && res.data) {
            const info = res.data.data[0][0];
            this.hotelDetails.important_info = info;
          } else {
            this.hotelDetails.important_info = "";
          }
        })
        .catch(error => {
          errorResponseHandler(error);
        });
    },

    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      this.createFile(files[0]);
    },

    onChange(e) {
      const { files } = e.target;
      this.createFile(files[0]);
    },

    createFile(file) {
      if (
        (file.type === "image/jpeg"
          || file.type === "image/jpg"
          || file.type === "image/png"
          || file.type === "image/gif")
        && file.size <= 1048576
      ) {
        this.uploadImage = file;
        this.imagePreview = URL.createObjectURL(file, file.name);
      } else {
        this.uploadImage = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only JPG, JPEG or PNG Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    removeFile() {
      if (this.uploadImage) {
        this.uploadImage = "";
        this.imagePreview = "";
      } else {
        this.imagePreview = "";
      }
    },

    disabledRange(date) {
      return date < new Date();
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}
</style>

<style lang="scss">
.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.img {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 400px;
  height: 250px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

// .drop {
//   background-color: #f2f2f2;
//   border: 4px dashed #ccc;
//   background-color: #f6f6f6;
//   border-radius: 2px;
//   height: 400px;
//   width: 600px;
//   max-width: 100%;
//   max-height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.mx-datepicker {
  width: 100% !important;
}
</style>

<style>
.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
</style>
