<template>
  <b-card-code>
    <b-col class="d-flex justify-content-end" cols="12" md="12">
      <div id="my-container">
        <!-- Our triggering (target) element -->
        <b-button
          ref="button"
          v-b-modal.modal-add-insurance
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="isAllAdded"
        >
          Add Insurance
        </b-button>
      </div>
    </b-col>
    <b-col>
      <b-table
        ref="refUserListTable"
        :items="insurances"
        class="position-relative mt-3 mb-3"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(sr_no)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(type)="data">
          {{
            data.item.type
              ? data.item.type.charAt(0).toUpperCase() + data.item.type.slice(1)
              : ""
          }}
        </template>

        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal.modal-add-insurance
              @click="openInsuranceModal(data.item.key)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">
                Edit
              </span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-col>
    <!-- submit and Next -->
    <b-col offset-md="5">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        @click="$emit('next-step')"
      >
        Save & Next
      </b-button>
    </b-col>

    <b-modal
      id="modal-add-insurance"
      title="Add Insurance Detail"
      cancel-title="Close"
      ok-title="Save"
      size="lg"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="AddInsuranceDetails"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row col>
            <b-col md="12">
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <b-form-group
                    label="Status"
                    label-for="mc-select-status"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      v-model="insuranceForm.status"
                      :options="BookingStatusEnum"
                      class="status"
                      placeholder="Select Status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12">
                <b-form-group label="Supplier" label-for="mc-supplier">
                  <validation-provider
                    #default="{ errors }"
                    name="Supplier"
                    rules="required"
                  >
                    <b-form-input
                      v-model="insuranceForm.supplier"
                      class="supplier"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Supplier"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Policy Code" label-for="mc-policy-code">
                  <validation-provider
                    #default="{ errors }"
                    name="Policy Code"
                    rules="required"
                  >
                    <b-form-input
                      v-model="insuranceForm.policy_code"
                      class="policy"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Policy Code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Type" label-for="mc-type">
                  <validation-provider
                    #default="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="insuranceForm.insurance_type"
                      class="type"
                      :options="insuranceTypes"
                      placeholder="Select Type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Start - End Date"
                  rules="required"
                >
                  <b-form-group
                    label="Select Start - End Date"
                    label-for="mc-select-date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <date-picker
                      v-model="insuranceDates"
                      type="date"
                      range
                      format="MM/DD/YYYY"
                      :open-date="new Date()"
                      :disabled-dates="{ to: new Date(Date.now() - 8640000) }"
                      placeholder="Select date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12">
                <b-form-group label="Days" label-for="mc-star">
                  <validation-provider
                    #default="{ errors }"
                    name="Days"
                    rules="required"
                  >
                    <b-form-input
                      v-model="days"
                      class="Days"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Days"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Total Cost" label-for="mc-star">
                  <validation-provider
                    #default="{ errors }"
                    name="Total Cost"
                    rules="required"
                  >
                    <b-form-input
                      v-model="insuranceForm.total_price"
                      class="Total Cost"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Total Cost"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Description" label-for="mc-description">
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                    rules="required"
                  >
                    <template>
                      <quill-editor
                        id="cancellation"
                        v-model="insuranceForm.description"
                        class="Description"
                        :options="snowOption"
                        :preserve-whitespace="false"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Description"
                      />
                    </template>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import vSelect from "vue-select";
import { resConditionCheck, BookingStatusEnum } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BookingService from "@/services/booking/booking.service";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BCardCode,
    BButton,
    DatePicker,
    BTable,
    quillEditor,
    BDropdown,
    BDropdownItem
  },

  directives: {
    "b-modal": VBModal,
    Ripple
  },

  props: {
    insuranceDetails: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      quoteId: this.$route.params.id ? this.$route.params.id : "",
      insuranceForm: {
        supplier: "",
        insurance_type: "",
        policy_code: "",
        start_date: "",
        end_date: "",
        status: "",
        total_price: "",
        currency_code: "HUF",
        description: ""
      },
      insuranceDates: "",
      insuranceTypes: [],
      insurances: this.insuranceDetails || [],
      isAllAdded: false,
      days: 0,
      tableColumns: [{ key: "sr_no" }, { key: "type" }, { key: "action" }],
      toolbarOptions: [],
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      },

      loading: false,
      BookingStatusEnum
    };
  },

  watch: {
    insuranceDates: {
      handler(newValue) {
        this.insuranceForm.start_date = newValue[0]
          ? moment(newValue[0]).format("YYYY-MM-DD")
          : "";
        this.insuranceForm.end_date = newValue[1]
          ? moment(newValue[1]).format("YYYY-MM-DD")
          : "";
        const timeDiff = Math.abs(
          newValue[1].getTime() - newValue[0].getTime()
        );
        this.days = Math.ceil(timeDiff / (1000 * 3600 * 24));
      }
    },

    insuranceDetails: {
      handler() {
        if (this.insuranceDetails.length > 1) {
          this.isAllAdded = true;
        } else {
          this.isAllAdded = false;
        }
      }
    }
  },

  mounted() {
    this.getInsuranceTypes();
  },

  methods: {
    AddInsuranceDetails(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true;
          this.insuranceForm.insurance_type = this.insuranceForm.insurance_type
            .replace(" Insurance", "")
            .toLowerCase();
          BookingService.updateInsurance(this.quoteId, this.insuranceForm)
            .then(res => {
              if (resConditionCheck(res.status) && res.data) {
                this.loading = false;
                this.$nextTick(() => {
                  this.$emit("on-submit");
                  this.$bvModal.hide("modal-add-insurance");
                });
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "X-CircleIcon",
                    variant: "success"
                  }
                });
              }
            })
            .catch(error => {
              this.loading = false;
              const errorData = errorResponseHandler(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger"
                }
              });
            });
        }
      });
    },

    async getInsuranceTypes() {
      const reqData = {
        transaction_id: 0,
        start_date: "2021-12-25",
        end_date: "2021-12-26",
        resort: "Paris",
        passengers: [
          {
            code: "10",
            quantity: "2"
          },
          {
            code: "8",
            quantity: "1"
          }
        ]
      };
      await BookingService.getInsuranceTypes(reqData)
        .then(res => {
          if (resConditionCheck(res.status) && res.data) {
            this.insuranceTypes = res.data.data.map(e => ({
              label: e.description,
              value: e.name
            }));
          }
        })
        .catch(error => {
          errorResponseHandler(error);
        });
    },

    openInsuranceModal(key) {
      // eslint-disable-next-line array-callback-return
      this.insurances.map(e => {
        if (e.key === key) {
          this.insuranceForm.supplier = e.supplierCode;
          this.insuranceForm.insurance_type = `${e.type
            .charAt(0)
            .toUpperCase() + e.type.slice(1)} Insurance`;
          this.insuranceForm.policy_code = e.policy_code;
          this.insuranceForm.start_date = new Date(e.startDate);
          this.insuranceForm.end_date = new Date(e.endDate);
          const insuranceDates = [];
          insuranceDates.push(new Date(e.startDate));
          insuranceDates.push(new Date(e.endDate));
          this.insuranceDates = insuranceDates;
          this.insuranceForm.status = e.status;
          this.insuranceForm.total_price = e.amount;
          this.insuranceForm.description = e.description;
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}

.mx-datepicker {
  width: 100% !important;
}
</style>
