<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Remarks" label-for="h-remarks">
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  rules="required"
                >
                  <b-form-input
                    id="h-remarks"
                    v-model="commentDetails.remarks"
                    placeholder="Remarks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Notes" label-for="notes">
                <validation-provider
                  #default="{ errors }"
                  name="Notes"
                  rules="required"
                >
                  <template>
                    <quill-editor
                      id="notes"
                      v-model="commentDetails.notes"
                      :options="snowOption"
                      :preserve-whitespace="false"
                    />
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit and Next -->
            <b-col offset-md="5">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="isSubmitted"
                @click="saveCommentDetail"
              >
                Save Booking
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import errorResponseHandler from "@/services/errorHandler";
import { required } from "@/@core/utils/validations/validations";
import { resConditionCheck } from "@/helpers/constant";
import bookingService from "@/services/booking/booking.service";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor
  },
  directives: {
    Ripple
  },

  props: {
    commentDetails: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      quoteId: this.$route.params.id ? this.$route.params.id : "",
      loading: false,
      isSubmitted: false,
      required,
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      }
    };
  },

  methods: {
    saveCommentDetail() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isSubmitted = true;
          this.loading = true;
          bookingService
            .updateComment(this.quoteId, this.commentDetails)
            .then(res => {
              if (resConditionCheck(res.status) && res.data) {
                this.isSubmitted = false;
                this.loading = false;
                this.$router.push(`/booking/list`);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Booking saved successfully.",
                    icon: "X-CircleIcon",
                    variant: "success"
                  }
                });
              }
            })
            .catch(error => {
              this.loading = false;
              const errorData = errorResponseHandler(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger"
                }
              });
            });
        }
      });
    }
  }
};
</script>

<style>
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}
</style>
